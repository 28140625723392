import React, { useState, useEffect } from "react";
import NoSelection from "../../GeneralDisplays/NoSelection";

import './AffiliateEdit.css';

const nothing = 0;
const viewAll = 1;
const viewAssociatedApplications = 2;
const viewChangeAffiliateStatus = 3;

export default function AffiliateEdit(props) {
    const affiliate = props.affiliate;
    const [navSelection, setNavSelection] = useState(viewAll);
    const [assoicatedApplications, setAssociatedApplications] = useState([]);
    const [approvedApplications, setApprovedApplications] = useState([]);
    const [deniedApplications, setDeniedApplications] = useState([]);

    useEffect(() => {
        // Find associated applications for use in component
        var localAssociatedApplications = [];
        affiliate.associatedApplications.forEach(applicationToken => {
            props.applications.forEach(application => {
                if (application.token === applicationToken) {
                    localAssociatedApplications.push(application);
                }
            });
        });

        // Find associated approved applications
        var localApprovedApplications = [];
        var localDeniedApplications = [];

        localApprovedApplications.forEach(application => {
            if (application.affiliateApproved === true) {
                localApprovedApplications.push(application);
            };
            if (application.affiliateDenied === true) {
                localDeniedApplications.push(application);
            };
        });

        setAssociatedApplications(localAssociatedApplications);
        setApprovedApplications(localApprovedApplications);
        setDeniedApplications(localDeniedApplications);
    }, []);

    const updateNavSelection = (selection) => {
        if (navSelection !== selection) {
            setNavSelection(selection);
        } else {
            setNavSelection(nothing);
        }
    }

    const renderAssociatedApplications = () => {

    }

    const renderSelection = () => {
        switch (navSelection) {
            case viewAll:
                return (
                    <>
                        <h1 className="header">All Information</h1>
                        <div className="affiliate-review-section">
                            <div className="employee-affiliate-review-items">
                                <div className="affiliate-review-item">
                                    <h3>Name:</h3>
                                    <p>{affiliate.name}</p>
                                </div>

                                <div className="affiliate-review-item">
                                    <h3>E-Mail:</h3>
                                    <p>{affiliate.email}</p>
                                </div>

                                <div className="affiliate-review-item">
                                    <h3>Type of Organization:</h3>
                                    <p>{affiliate.type}</p>
                                </div>

                                <div className="affiliate-review-item">
                                    <h3>Applications Referred:</h3>
                                    <p>{assoicatedApplications.length}</p>
                                </div>

                                <div className="affiliate-review-item">
                                    <h3>Applications Approved:</h3>
                                    <p>{approvedApplications.length}</p>
                                </div>

                                <div className="affiliate-review-item">
                                    <h3>Applications Denied:</h3>
                                    <p>{deniedApplications.length}</p>
                                </div>
                            </div>
                        </div>
                    </>
                )

            case viewAssociatedApplications:
                return (
                    <>
                        <h1 className="header">Associated Applications</h1>
                        <ul className="applications-list">
                            {assoicatedApplications.map((application, index) => {
                                
                            })}
                        </ul>
                    </>
                )

            case viewChangeAffiliateStatus:
                return (
                    <>
                        <h1 className="header">Change Affiliate Status</h1>
                    </>
                )

            case nothing:
                return (
                    <NoSelection />
                )

            default: {
                return (
                    <NoSelection />
                )
            }
        }
    }

    return (
        <>

            <div className='back-button'>
                <button className='denied-button' onClick={() => props.setEditing(false)}><i class="fa-solid fa-left-long"></i> Go Back</button>
            </div>

            <h1 className="header">
                Viewing {affiliate.name}
            </h1>

            <div className="affiliate-edit-nav">
                <button className="all-button" onClick={() => {
                    updateNavSelection(viewAll);
                }}>
                    View Info
                </button>

                <button className="search-button" onClick={() => {
                    updateNavSelection(viewAssociatedApplications);
                }}>
                    View Associated Applications
                </button>

                <button className="approved-button" onClick={() => {
                    updateNavSelection(viewChangeAffiliateStatus);
                }}>
                    Change Affiliate Status
                </button>
            </div>

            <div className="affiliate-edit">
                {renderSelection()}
            </div>

        </>
    )
}