import React from "react";
import "./AttendeeContainer.css";

export default function AttendeeContainer(props) {
    const person = props.person;
    const questions = props.questions;

    const renderQuestions = () => {
        if (questions !== null && person.questionAnswers !== undefined) {
            return (
                <>
                    {person.questionAnswers.map((answer, index) => {
                        return (
                            <>
                                <h3>{questions[index].question}:</h3>
                                <p>{answer}</p>
                            </>)
                    })}
                </>
            )
        }
    }
    return (
        <>
            <div className="search-item">
                <h2 className="header">{person.firstName} {person.lastName} | {person.email}</h2>
                <p>{person.info}</p>
                <div className="question-answer-container">
                    {renderQuestions()}
                </div>
            </div>
        </>
    )
}