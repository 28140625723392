import React from "react";
import './EventSigninContainer.css';

export default function EventSigninContainer(props) {
    const person = props.person;

    const renderBleedingDisorderInformation = () => {
        if (!person.bleedingDisorder) {
            return (
                <>
                    <div className="event-edit-signin-information-item">
                        <h3>Bleeding Disorder:</h3> <p>None</p>
                    </div>
                </>
            )
        }

        return (
            <>
                <div className="event-edit-signin-information-item">
                    <h3>Relationship to Bleeding Disorder:</h3> <p>{person.bleedingDisorderRelationship}</p>
                </div>

                <div className="event-edit-signin-information-item">
                    <h3>Type of Bleeding Disorder:</h3> <p>{person.bleedingDisorderType}</p>
                </div>

                <div className="event-edit-signin-information-item">
                    <h3>Bleeding Disorder Severity:</h3> <p>{person.bleedingDisorderSeverity}</p>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="event-edit-signin-container">
                <h2 className="header">
                    {person.firstName} {person.lastName} | {person.email} | {person.groupMembers} in Party
                </h2>
                <h2 className="event-edit-signin-section-header header">General Information:</h2>
                <div className="event-edit-signin-information-list">
                    <div className="event-edit-signin-information-item">
                        <h3>E-Mail:</h3> <p>{person.email}</p>
                    </div>

                    <div className="event-edit-signin-information-item">
                        <h3>Birthday:</h3> <p>{person.birthday}</p>
                    </div>

                    <div className="event-edit-signin-information-item">
                        <h3>Gender:</h3> <p>{person.gender}</p>
                    </div>

                    <div className="event-edit-signin-information-item">
                        <h3>Phone Number:</h3> <p>{person.phone}</p>
                    </div>

                    <div className="event-edit-signin-information-item">
                        <h3>Address:</h3> <p>{person.streetAddress} {person.streetAddress2}</p>
                        <p>{person.city} {person.state}</p>
                    </div>

                    {renderBleedingDisorderInformation()}
                </div>

                <h2 className="event-edit-signin-section-header header">Consents:</h2>

                <div className="event-edit-signin-opt-ins-list">
                    <div className="event-edit-signin-opt-in-item">
                        <h3>Consent to Photo Use?</h3>
                        {person.photoOptIn ? "Yes" : "No"} {person.photoOptIn ? <i class="fa-solid fa-check green-check"/> : <i class="fa-thin fa-asterisk red-asterisk" />} 
                    </div>

                    <div className="event-edit-signin-opt-in-item">
                        <h3>Consent to Sponsor Contact?</h3>
                        {person.sponsorContactOptIn ? "Yes" : "No"} {person.sponsorContactOptIn ? <i class="fa-solid fa-check green-check"/> : <i class="fa-thin fa-asterisk red-asterisk" />} 
                    </div>
                </div>
            </div>
        </>
    )
}