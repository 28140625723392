import React, { useState } from "react";

import { Fade } from "react-reveal";

// Custom Data for Directors, update here when needed
import { DirectorsData } from "./DirectorsData";

export default function MeetTheTeam(props) {
    var height = props.height;
    var width = props.width;

    return (
        <>
            <div className="meet-the-team-content">
                <div className='team-container-large'>
                    <Fade delay={150}>
                        <div className='team-box-large'>
                            <div className='title-section'>
                                <div>
                                    <h1 className='team-title-large'>Bonnie Culver</h1>
                                    <h2 className='team-subtitle-large'>President and CEO</h2>
                                </div>

                                <div>
                                    <img className="team-image-large" alt="picture of bonnie culver" src="/images/bonnie-culver.jpg" />
                                </div>
                            </div>

                            <div className="description-section">
                                <p>With more than 25 years experience in the hemophilia community I have a vested interest in the well-being and health of my fellow bleeders.
                                    I am a proud supporter of the National Hemophilia Foundation, the Hemophilia Federation of America, The Hemophilia Foundation of Michigan and the Great Lakes Hemophilia Foundation. In fact, their service to the community inspired me to do the same.
                                    I consider myself fortunate to be able to devote my time and energy to a career in the non-profit sector that allows me to help others.</p>
                                <p>
                                    As the President and CEO of HUGS I pledge to do all I can to help meet the needs of those with bleeding disorders.
                                    I will strive to provide advocacy, education and financial assistance within my funding abilities.
                                    I will be a listening ear when you need to talk, or vent frustrations and I will help guide you to make informed decisions.
                                </p>
                            </div>
                        </div>
                    </Fade>

                    <Fade delay={150}>
                        <div className='team-box-large row-reverse'>

                            <div className='title-section'>
                                <div>
                                    <h1 className='team-title-large'>Stormy Johnson</h1>
                                    <h2 className='team-subtitle-large'>Executive Director</h2>
                                </div>

                                <div>
                                    <img className="team-image-large" alt="picture of stormy johnson" src="/images/stormy-johnson.jpg" />
                                </div>

                            </div>

                            <div className="description-section">
                                <p>With over 20 years of dedicated service in the bleeding disorders community, Stormy Johnson is a passionate advocate for women facing these challenges. As a public speaker, she shares her insights and experiences to raise awareness and promote understanding of bleeding disorders.</p>
                                <p>Stormy has extensive experience in the nonprofit sector, having served on various advisory boards and community projects, where she actively contributes to initiatives that enhance support and resources for those affected. Her commitment to helping others navigate their health journeys is driven by a deep passion for ensuring everyone receives the proper diagnosis and treatment they need.</p>
                            </div>
                        </div>
                    </Fade>

                    <Fade delay={150}>
                        <div className='team-box-large'>
                            <div className='title-section'>
                                <div>
                                    <h1 className='team-title-large'>Susan Hargus</h1>
                                    <h2 className='team-subtitle-large'>Associate Director</h2>
                                </div>

                                <div>
                                    <img className="team-image-large" alt="picture of susan hargus" src="/images/susan-hargus.jpg" />
                                </div>
                            </div>

                            <div className="description-section">
                                <p>Understanding why people need what they think they need and actual needs is important.
                                    Having basic needs in place is the first building block to becoming and sustaining stability.
                                    It is my desire to help people to retain this level of life. We all need help in one way or another in life.
                                    Not all needs are financial, but it is a start.</p>
                                <p>My experiences for the past 20 years in the insurance industry reflects my interest in helping others needs.
                                    Worked in several fields that focused on helping others in need.
                                    To further my ability to increase my skills and knowledge completed a Master Degree in Business Administration from Capella.</p>
                            </div>
                        </div>
                    </Fade>

                </div>

                <div className="team-container-medium">

                    <Fade>
                        <div className='team-box-medium'>
                            <h1 className='team-title-medium'>Stone Culver</h1>
                            <h2 className='team-subtitle-medium'>Co-Director & Fullstack Developer</h2>
                            <img src="images/stone-culver-2024.jpg" className='team-image-medium' alt="picture of stone culver" />
                        </div>
                    </Fade>

                    <Fade>
                        <div className='team-box-medium'>
                            <h1 className='team-title-medium'>Brandee Cookston</h1>
                            <h2 className='team-subtitle-medium'>Social Media Manager</h2>
                            <img src="images/brandee-cookston.png" className='team-image-medium' alt="picture of brandee cookston" />
                        </div>
                    </Fade>
                </div>
            </div>

            <Fade>
                <div className='small-header'>
                    <h1 className='header'>Board of Directors</h1>
                </div>
            </Fade>

            <ul className="team-container-small">
                {DirectorsData.map((item, index) => {
                    return (
                        <Fade delay={index * 150}>
                            <li className="team-box-small" key={index}>
                                <h1 className='team-title-small'>{item.name}</h1>
                                <h2 className='team-subtitle-small'>{item.title}</h2>
                                <img className='team-image-small' src={item.img} alt={item.alt} />
                            </li>
                        </Fade>
                    )
                })}
            </ul>

            <Fade>
                <div className='small-header'>
                    <h1 className='header'>Interested in Joining the Team?</h1>
                </div>
            </Fade>
            <Fade>
                <div className='team-join-container'>
                    <div>
                        <form action="/events">
                            <button action="submit" className="approved-button">Join the Team</button>
                        </form>
                    </div>
                </div>
            </Fade>
        </>
    )
}