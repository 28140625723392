import React, { useState } from "react";
import ApplicaitonPreview from "./ApplicationPreview";

// Export of 'renderApplicationItem' into component for reuse
// Takes 'application' object, 'opened' string, onApplicationClick & onViewFullClick functions
// and width
// Changes the value of 'opened' to the current clicked item, revealing all information about the application
export default function ApplicationItem(props) {
    const application = props.application;

    if (props.opened === application.token) {
        return (
            <li className="application-container"
                key={application.token}>
                <button className={application.status === "Approved" ? "application approved" :
                    application.status === "Pending" ? "application pending" :
                        application.status === "Denied" ? "application denied" : "application"}
                    onClick={() => props.onApplicationClick('')}>
                    <i class="fa-solid fa-angle-down" />
                    {props.width > 880 ?
                        " Application for " + application.firstName + " " + application.lastName + " - " + application.token + " | Submitted on - " + application.issuedOn + " | Status - " + application.status
                        : "Application " + application.token + " | Status - " + application.status} </button>
                <ApplicaitonPreview firstName={application.firstName} lastName={application.lastName}
                    gender={application.gender} birthday={application.birthday} email={application.email}
                    phone={application.phone} type={application.type} method={application.method} amount={application.amount}
                    status={application.status} details={application.details} />
                <div>
                    <button className='view-full-button approved-button'
                        onClick={() => props.onViewFullClick(application)}
                    >{application.status === "Pending" ? "View Full Details / Respond" :
                        "View Full Details / Change Decision"}</button>
                </div>

            </li>
        )
    } else {
        return (
            <li className='application-container' key={application.token}>
                <button className={application.status === "Approved" ? "application approved" :
                    application.status === "Pending" ? "application pending" :
                        application.status === "Denied" ? "application denied" : "application"}
                    onClick={() => props.onApplicationClick(application.token)}>
                    <i class="fa-solid fa-angle-right" />
                    {props.width > 880 ?
                        " Application for " + application.firstName + " " + application.lastName + " - " + application.token + " | Submitted on - " + application.issuedOn + " | Status - " + application.status
                        : "Application " + application.token + " | Status - " + application.status}
                </button>
            </li>
        )
    }
}